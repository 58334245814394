import { FC, useState, useEffect, AnchorHTMLAttributes } from 'react'
import Link, { LinkProps } from 'next/link'
import tw from 'tailwind-styled-components'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import { useCurrentUserQuery } from '@foros-fe/graphql'
import { useScrollBlock, useLogout } from '../../hooks'
import { Button } from '../Button'
import { AuthModal } from '../AuthModal'
import { Burger } from './Burger'
import { SvgIcon } from '../SvgIcon'
import { ProfileMenu } from '../ProfileMenu'
import { domains, theme, api } from '../../config'
import ForosLogo from '@foros-fe/assets/icons/foros-logo.svg'
import MALogo from '@foros-fe/assets/images/ma-logo.png'
import PhoneIcon from '@foros-fe/assets/icons/phone.svg'
import AuctionIcon from '@foros-fe/assets/icons/document.svg'

type ResponsiveMenuProps = {
  $open: boolean
}
type PropsLink = Pick<LinkProps, 'href'> & Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>

const BlueBar = tw.div`bg-primary-500 h-9 w-[100%] flex`
const BlueBarText = tw.p`ml-2 text-white text-sm font-semibold`
const TopContainer = tw.div`filter border-b border-gray-300 bg-white h-[68px] flex`
const Container = tw.div`
  container
  flex
  flex-1
  items-center
`
const DesktopBlock = tw.div`hidden md:block lg:flex lg:flex-1`
const MobileBlock = tw.div`md:hidden`
const LogInAnchor = tw.a`
  text-lg
  md:text-base
  text-primary-500
  font-medium
  cursor-pointer
  transition-all
`
const TwAnchor = tw(LogInAnchor)`
  mb-2
  lg:mb-0
  md:relative
  md:inline-block
  lg:flex
  lg:items-center
  top-1/2
  -translate-y-1/2
`
const Anchor = styled(TwAnchor)<{ $active?: boolean }>`
  &:after {
    content: '';
    position: absolute;
    height: 4px;
    background-color: ${({ $active }) =>
      $active ? theme.colors.secondary[500] : theme.colors.transparent};
  }
  @media (min-width: 768px) {
    &:after {
      bottom: -22px;
      left: 0;
      right: 0;
    }
  }
  @media (min-width: 1152px) {
    &:after {
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
`
const ResponsiveMenu = tw.div<ResponsiveMenuProps>`
  ${({ $open }) => ($open ? 'visible' : 'invisible')}
  ${({ $open }) => ($open ? 'opacity-1' : 'opacity-0')}
  transition-all
  duration-500
  ease-out
  absolute
  bg-white
  bg-opacity-100
  bottom-0
  left-0
  right-0
  flex
  flex-col
  pt-5
  px-2
  text-2xl
  z-[500]
`
const NavLink: FC<PropsLink> = ({ href, ...props }) => {
  const { pathname } = useRouter()

  return (
    <Link href={href}>
      <Anchor $active={pathname.includes(href.toString())} {...props} />
    </Link>
  )
}

const Logo = () => {
  switch (domains.current.name) {
    case 'miskoaukcionas.lt':
      return <Image src={MALogo} width={176.844} height={45} />

    default:
      return <SvgIcon src={ForosLogo} width={100} height={24} />
  }
}

const { consultationPhoneNumber } = domains.current

const LeadInquiryBar = () => {
  const { t } = useTranslation('common')

  return (
    <BlueBar>
      <Container className='justify-center md:justify-between'>
        <a href={`tel:${consultationPhoneNumber}`} className='flex'>
          <SvgIcon src={PhoneIcon} size={20} />
          <BlueBarText className='hidden md:block'>{t('freeConsultation')}</BlueBarText>
          <BlueBarText>{consultationPhoneNumber}</BlueBarText>
        </a>
        <Link href='/auctions/inquiry'>
          <a className='hidden md:flex'>
            <SvgIcon src={AuctionIcon} width={17} height={20} />
            <BlueBarText>{t('freeAuctionStart')}</BlueBarText>
          </a>
        </Link>
      </Container>
    </BlueBar>
  )
}

export const NavBar: FC = () => {
  const { t } = useTranslation('common')
  const [responsiveMenuOpen, toggleResponsiveMenu] = useState(false)
  const [authOpen, setAuthOpened] = useState(false)
  const [registration, setRegistration] = useState(false)
  const { blockScroll, allowScroll } = useScrollBlock()
  const { data } = useCurrentUserQuery()
  const logout = useLogout()

  useEffect(() => {
    if (responsiveMenuOpen) {
      blockScroll()
    } else {
      allowScroll()
    }

    return allowScroll
  }, [responsiveMenuOpen])

  const openLogin = () => {
    toggleResponsiveMenu(false)
    setRegistration(false)
    setAuthOpened(!authOpen)
  }
  const openRegistration = () => {
    toggleResponsiveMenu(false)
    setRegistration(true)
    setAuthOpened(!authOpen)
  }
  const openAdmin = () => (window.location.href = api.adminURL)
  const logoutOnClick = () => {
    logout()
    toggleResponsiveMenu(false)
  }

  const dashboardEnabled = domains.current.name !== 'foros.lv'
  const user = data?.currentUser

  return (
    <div className='z-50'>
      {user ? null : <LeadInquiryBar />}
      <TopContainer>
        <Container>
          <Link href={user && dashboardEnabled ? '/dashboard' : '/'}>
            <a className='flex flex-1 items-center'>
              <Logo />
            </a>
          </Link>
          <MobileBlock>
            <Burger toggled={responsiveMenuOpen} toggle={toggleResponsiveMenu} />
          </MobileBlock>
          <DesktopBlock className='h-full md:space-x-4 lg:space-x-8 xs:mr-0 md:mr-4 lg:mr-0 justify-center'>
            {user && dashboardEnabled ? (
              <NavLink href='/dashboard'>{t('nav.dashboard')}</NavLink>
            ) : null}
            <NavLink href='/auctions'>{t('nav.auctions')}</NavLink>
            {user ? null : <NavLink href='/about-us'>{t('nav.aboutUs')}</NavLink>}
            <NavLink href='/contact-us'>{t('nav.contactUs')}</NavLink>
            {user?.isEmployee && <Anchor onClick={openAdmin}>{t('nav.admin')}</Anchor>}
          </DesktopBlock>
          <DesktopBlock className='md:pl-4 lg:pl-0 md:border-l-2 lg:border-0 md:flex justify-end items-center'>
            {user ? (
              <ProfileMenu />
            ) : (
              <>
                <LogInAnchor onClick={openLogin}>{t('nav.logIn')}</LogInAnchor>
                <Button
                  value='nav-sign-up'
                  title={t('nav.signUpFree')}
                  className='ml-4'
                  onClick={openRegistration}
                />
              </>
            )}
          </DesktopBlock>
        </Container>
      </TopContainer>
      <AuthModal registration={registration} open={authOpen} onClose={() => setAuthOpened(false)} />
      <ResponsiveMenu $open={responsiveMenuOpen} className={user ? 'top-[68px]' : 'top-[104px]'}>
        {user ? (
          dashboardEnabled ? (
            <NavLink href='/dashboard'>{t('nav.dashboard')}</NavLink>
          ) : null
        ) : (
          <Anchor onClick={openLogin}>{t('nav.logIn')}</Anchor>
        )}
        <NavLink href='/auctions'>{t('nav.auctions')}</NavLink>
        <NavLink href='/about-us'>{t('nav.aboutUs')}</NavLink>
        <NavLink href='/contact-us'>{t('nav.contactUs')}</NavLink>
        {user?.isEmployee && <Anchor onClick={openAdmin}>{t('nav.admin')}</Anchor>}
        {user ? (
          <>
            <NavLink href='/settings'>{t('nav.settings')}</NavLink>
            <Anchor onClick={logoutOnClick}>{t('nav.logout')}</Anchor>
          </>
        ) : (
          <Button
            value='nav-sign-up'
            title={t('nav.signUpFree')}
            className='text-xl self-start'
            onClick={openRegistration}
          />
        )}
      </ResponsiveMenu>
    </div>
  )
}
