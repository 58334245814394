import React, { FC, useState, useEffect } from 'react'
import tw from 'tailwind-styled-components'
import Link from 'next/link'
import { useForm } from 'react-hook-form'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import {
  useCreateLeadUserMutation,
  useRegistrationTypeQuery,
  useImperativeQuery,
  SignUpType,
} from '@foros-fe/graphql'
import { Modal } from './Modal'
import { TextInput } from './Inputs/TextInput'
import { CheckBox } from './Inputs/CheckBox'
import { Button } from './Button'
import { Form } from './Forms/Form'
import { emailKey, leadUserKey } from '../config'
import { setFormFieldErrorTranslation, setFormFieldsErrorTranslations } from '../utils'

type Props = {
  registration?: boolean
  open: boolean
  onClose(): void
}
type IFormInputs = {
  email: string
  news: boolean
  terms: boolean
}

const Title = tw.p`text-2xl text-center mb-6 font-medium`
const NavLink = tw.span`text-primary-500 hover:underline cursor-pointer`
const defaultValues = { terms: false, news: false, email: '' }

export const AuthModal: FC<Props> = ({ open = false, onClose, registration = false }) => {
  const router = useRouter()
  const { t } = useTranslation()
  const [createLeadUserMutation] = useCreateLeadUserMutation()
  const registrationTypeQuery = useImperativeQuery(useRegistrationTypeQuery)
  const [registrationView, setRegistrationView] = useState(registration)
  const methods = useForm<IFormInputs>({ defaultValues })
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { register, reset, setError, setFocus } = methods

  useEffect(() => {
    setFocus('email')
    setRegistrationView(registration)
  }, [registration, open])

  useEffect(() => {
    setFocus('email')
  }, [registrationView])

  const handleClose = () => {
    reset(defaultValues)
    onClose()
  }
  const onSubmit = async (data: IFormInputs) => {
    if (registrationView) {
      const recaptchaToken = await executeRecaptcha?.('authentication')
      const { data: apiData } = await createLeadUserMutation({
        variables: {
          input: {
            email: data.email,
            termsAndConditionsAccepted: data.terms,
            marketingConditionsAccepted: data.news,
            recaptchaToken,
          },
        },
      })

      const error = apiData?.createLeadUser?.error
      const leadUser = apiData?.createLeadUser?.leadUser

      if (error) {
        setFormFieldsErrorTranslations(setError, error, { email: 'CreateLeadUserEmailError' })

        setFormFieldErrorTranslation(
          setError,
          'terms',
          error.termsAndConditionsAccepted,
          'CreateLeadUserTermsAndConditionsAcceptedError'
        )

        return
      }

      sessionStorage.setItem(leadUserKey, JSON.stringify(leadUser))
    } else {
      const { email } = data
      const { data: apiData } = await registrationTypeQuery({ email })

      const registrationType = apiData?.userRegistrationType
      if (registrationType === SignUpType.Lead) {
        return setRegistrationView(true)
      } else if (registrationType === SignUpType.Authenticated) {
        sessionStorage.setItem(emailKey, email)
      } else {
        return setError('email', { message: t('errors.emailUserDoesNotExist') })
      }
    }

    router.push({
      pathname: registrationView ? '/sign-up' : '/sign-in',
    })
  }

  return (
    <Modal open={open} onClose={handleClose} onExited={() => setRegistrationView(registration)}>
      <Form<IFormInputs> {...methods} onSubmit={onSubmit}>
        <Title>{registrationView ? t('createAccount') : t('logInToAccount')}</Title>
        <TextInput
          label={t('email')}
          className='mb-6 min-w-[310px] md:min-w-[360px]'
          {...register('email')}
        />
        {registrationView && (
          <>
            <CheckBox label={t('marketingAgreement')} className='mb-2' {...register('news')} />
            <CheckBox
              label={
                <span>
                  {t('common:iAgreeWith')}{' '}
                  <Link href='/rules'>
                    <NavLink>{t('nav.rules')}</NavLink>
                  </Link>{' '}
                  {t('common:connectors.and')}{' '}
                  <Link href='/privacy-policy'>
                    <NavLink>{t('nav.privacyPolicy')}</NavLink>
                  </Link>
                </span>
              }
              className='mb-6'
              {...register('terms')}
            />
          </>
        )}
        <Button
          title={t('continue')}
          value={registrationView ? 'register-modal' : 'login-modal'}
          className='w-full mb-6'
          type='submit'
        />
      </Form>
      <p className='text-center text-gray-800'>
        {registrationView ? t('haveAccount') : t('needAccount')}&nbsp;
        <NavLink onClick={() => setRegistrationView(!registrationView)}>
          {registrationView ? t('common:nav.logIn') : t('common:nav.signUp')}
        </NavLink>
      </p>
    </Modal>
  )
}
