import React, { FC, useEffect } from 'react'
import Backdrop, { BackdropProps } from '@material-ui/core/Backdrop'
import tw from 'tailwind-styled-components'
import styled from 'styled-components'
import { useScrollBlock } from '../hooks'
import { CloseButton } from './CloseButton'
import { Card } from './Card'

export type Props = Omit<BackdropProps, 'transitionDuration'> & {
  onClose?(open: boolean): void
  cardClassname?: string
}

const StyledBackdrop = styled(Backdrop).attrs({
  transitionDuration: 300,
})`
  && {
    z-index: 500;
  }
`
const Container = tw(Card)`
  py-8
  lg:px-6
  border
  border-gray-400
  min-w-[360px]
`
const StyledClose = tw(CloseButton)`
  absolute
  top-[6px]
  right-[6px]
  md:top-[12px]
  md:right-[12px]
`

export const Modal: FC<Props> = ({
  open = false,
  onClose,
  cardClassname = '',
  children,
  ...props
}) => {
  const { blockScroll, allowScroll } = useScrollBlock()

  useEffect(() => {
    if (open) {
      blockScroll()
    } else {
      allowScroll()
    }

    return allowScroll
  }, [open])

  return (
    <StyledBackdrop open={open} {...props}>
      <Container className={cardClassname}>
        <StyledClose onClick={() => onClose?.(!open)} />
        {children}
      </Container>
    </StyledBackdrop>
  )
}
