import { FC } from 'react'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { SvgIcon } from './SvgIcon'
import { Menu } from './Menu'
import { useLogout } from '../hooks'
import ProfileIcon from '@foros-fe/assets/icons/profile.svg'

export const ProfileMenu: FC = () => {
  const { t } = useTranslation()
  const logout = useLogout()

  return (
    <Menu
      trigger={
        <div className='w-[27px] h-[27px] flex items-center justify-center'>
          <SvgIcon src={ProfileIcon} size={27} className='cursor-pointer' />
        </div>
      }
      position={'bottom right'}
    >
      {(close) => {
        const onClick = () => {
          logout()
          close()
        }

        return (
          <>
            <Link href='/settings'>
              <Menu.Item>{t('nav.settings')}</Menu.Item>
            </Link>
            <Menu.Item onClick={onClick}>{t('nav.logout')}</Menu.Item>
          </>
        )
      }}
    </Menu>
  )
}
